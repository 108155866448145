export default {
  data() {
    return {
      user: null,
      notImplementedAttributes: {
        company_name: '',
        company_address: '',
        ogrn: '',
        inn: '',
        kpp: '',
        correspondents_account: '',
        settlement_account: '',
        bank_account: '',
        bank_name: '',
        bik: ''
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t('profileSettings.bankDetails.pageTitle')
    }
  },

  created() {
    this.user = Object.assign({}, this.notImplementedAttributes, this.$currentUser)
  }
}
